import React from 'react';

function LatestWork() {
    return (
        <div>
            {/* Your component content goes here */}
        </div>
    );
}

export default LatestWork;